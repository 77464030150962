<template>
  <xiaoxifabu
    v-show="out.xiaoxifabu1"
    :out="out"
    @fabu="getxianshi"
  ></xiaoxifabu>
  <div>
    <div class="top">
      <div class="top-con">
        <div class="left">
          <a>石家庄</a>
        </div>
        <div class="right">
          <div class="con1" v-if="this.name != undefined">
            欢迎{{ this.name }}
          </div>
          <div class="con1" v-if="this.name == undefined">
            <router-link to="/Denglu">登录</router-link>
            /
            <router-link to="/zhuce">注册</router-link>
          </div>
          <div class="con2">
            个人中心
            <img src="../img/xx.png" alt="" width="15px" />
            <div class="xl-con">
              <a @click="getgeren"
                >个人中心
                <img src="../img/xs.png" alt="" width="15px" />
              </a>
              <a>我的账户</a>
              <a>我的求职</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="sousuo">
      <div>
        <img id="bigLogo" src="../img/logo-i.png" style="width: 60px" />
        <span class="logo">聚信人和</span>
      </div>
      <div id="sear">
        <div id="sarekey">
          <span>
            <input type="text" id="company" class="keyword2" />
          </span>
        </div>
        <div class="inputbtn">
          <input
            type="submit"
            name="button"
            value="搜&nbsp&nbsp索"
            class="btnall"
          />
        </div>
        <div class="fbbtn" @click="xiaoxifabu">
          <a class="postbtn">信息发布</a>
        </div>
      </div>
    </div>
    <section id="selection">
      <dl class="secitem">
        <dt class="secitembrand" style="height: 100px">职位：</dt>
        <dd>
          <router-link to="/">服务员</router-link>
          <router-link to="/shangpin">保洁</router-link>
          <router-link to="/renshi">人事专员/助理</router-link>
          <router-link to="/xiaoshou">销售</router-link>
          <br />
          <router-link to="/siji">司机</router-link>
          <router-link to="/kefu">客服</router-link>
          <router-link to="/canyin">餐饮</router-link>
          <router-link to="/jiudian">酒店</router-link>
          <router-link to="/meifa" style="color: #ff552e"
            >美容/美发</router-link
          >
          <router-link to="/jianshen">运动健身</router-link>
        </dd>
      </dl>
    </section>
    <div class="containnerwrap clearfix">
      <section id="mainlist" class="main clearfix pr">
        <div class="tabsbar">
          <div class="list-tabs list-tabs-es">
            <a href="" class="sel"> 职位 </a>
          </div>
        </div>
      </section>
    </div>
    <div class="cleft" id="infolist">
      <el-table
        :data="tableData"
        style="width: 100%; margin-left: 450px"
        :show-header="false"
        @cell-click="xixinxiangqing"
      >
        <el-table-column prop="title" />
        <el-table-column>
          <template #default="scope">
            <h3 style="margin-left: 10px">{{ scope.row.companyName }}</h3>
            <span>{{ scope.row.title }}</span>
          </template>
        </el-table-column>

        <el-table-column>
          <template #default="scope">
            <span style="margin-left: 10px"
              >{{ scope.row.salaryS }}元-{{ scope.row.salaryE }}元</span
            >
          </template>
        </el-table-column>
      </el-table>
      <el-button
        style="margin-left: 850px; margin-top: 90px"
        @click="huoqushuju"
        >下一页</el-button
      >
    </div>

    <!-- 底部备案号 -->
    <div class="bottom">
      <div>
        <a
          target="_blank"
          href="https://beian.miit.gov.cn/#/Integrated/recordQuery"
          rel="nofollow"
          >冀ICP备20010751号</a
        ><span>|</span
        ><a
          target="_blank"
          href="https://beian.miit.gov.cn/#/Integrated/recordQuery"
          rel="nofollow"
          >冀ICP备20010751号-2</a
        ><span>|</span
        ><a
          target="_blank"
          href="https://beian.miit.gov.cn/#/Integrated/recordQuery"
          rel="nofollow"
          >津文执网文【2019】</a
        ><span>|</span
        ><a
          target="_blank"
          href="https://beian.miit.gov.cn/#/Integrated/recordQuery"
          rel="nofollow"
          >河北坤洲网络科技有限公司</a
        ><span>|</span
        ><a
          target="_blank"
          href="https://beian.miit.gov.cn/#/Integrated/recordQuery"
          >联系我们</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import xiaoxifabu from "../components/xiaoxifabu.vue";
import axios from "axios";
import Cookies from "js-cookie";

export default {
  components: { xiaoxifabu },

  name: "Shangpin",
  data() {
    return {
      type: "美发",
      cur: 1, //yeshu
      pageSize: 5, //shuju
      name: "",
      // list: [],
      out: { xiaoxifabu1: false, token: "" },
      indexs: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      tableData: [],
    };
  },
  mounted() {
    this.name = Cookies.get("phone");
    this.out.token = Cookies.get("token");
    this.huoqushuju();
  },
  methods: {
    xiaoxifabu() {
      if (this.name != undefined) {
        this.out.xiaoxifabu1 = true;
      } else {
        alert("请先登录");
        this.$router.push("./Denglu")
      }
    },
    getxianshi(e) {
      this.out.xiaoxifabu1 = e;
    },
    getgeren() {
      if (this.name == "") {
        this.$router.push("./Denglu");
      } else {
        this.$router.push("./gerenzhongxin");
      }
    },
    huoqushuju() {
      console.log("asuhd", this.cur);
      axios
        .get(
          "http://123.56.43.64:15001/message/" +
            // "http://192.168.28.37:15001/message/"
            this.type +
            "?pageNum=" +
            this.cur +
            "&pageSize=" +
            this.pageSize
        )
        .then((res) => {
          this.cur++;

          console.log("xibaxiba ", res);
          this.tableData.push(...res.data.data.list);
        });
    },
    xixinxiangqing(item) {
      console.log("在西南岔宿管21321312", item.id);
      let id = item.id;
      this.$router.push({ name: "baojie1", params: { id } });
    },
  },
};
</script>

<style scoped>
.xl-con a {
  width: 100%;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #555;
  white-space: nowrap;
}

.xl-con a:first-child {
  color: #ff552e;
}

.con2 img {
  width: 9px;
  margin-left: 9px;
}

.con2:hover .xl-con {
  display: block;
}

.xl-con {
  position: absolute;
  top: -1px;
  border: 1px solid #ddd;
  padding: 0 10px;
  display: none;
  z-index: 99;
  background-color: #fff;
}

.con2 {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
  margin-left: 10px;
  padding: 0 10px;
  color: #555;
}

.con1 a {
  color: #555;
  margin: 0 2px;
}

.left {
  color: red;
  margin-left: 150px;
}

.right {
  display: flex;
  height: 100%;
  align-items: center;
}

.top {
  width: 100%;
  height: 35px;
  line-height: 35px;
  background: #fff;
  color: #555;
  border-bottom: 1px solid #ddd;
}

.top-con {
  width: 1190px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

/* 这个算通用样式，初始化 */
body {
  font-size: 12px;
  margin: 0;
  color: rgb(51, 51, 51);
  min-width: 1190px;
}

button {
  cursor: pointer;
}

img {
  vertical-align: middle;
  border: 0;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  cursor: pointer;
}
li,
ul {
  list-style: none;
}
.sousuo {
  width: 1200px;
  margin: 0 auto;
  background-color: #fff;
}

#bigLogo {
  display: inline-block;
  margin: 10px 30px 0px 150px;
}
#sear {
  margin-left: 400px;
  margin-top: -30px;
}
.fbbtn {
  display: block;
  margin-left: 500px;
}
.postbtn {
  width: 100px;
  height: 90px;
  color: #ff552e;
  background: #ffefe7;
  line-height: 36px;
  text-align: center;
  font-size: 20px;
  margin-left: 60px;
  position: relative;
  padding: 5px;
  top: -35px;
}
#sarekey {
  display: inline-block;
  width: 400px;
  border: 2px solid #ff552e;
  margin-top: -40px;
}
.keyword2 {
  outline: none;
}

#company {
  width: 400px;
  font-size: 14px;
  color: #ccc;
  border: 0;
  height: 35px;
  line-height: 38px;
  overflow: hidden;
}

.inputbtn {
  display: inline-block;
  float: right;
  margin-right: 300px;
  margin-top: -7px;
}

input.btnall {
  background: #ff552e;
  border: none;
  height: 39px;
  color: #fff;
  font-size: 16px;
  width: 100px;
  margin: 0 auto;
}
#selection {
  width: 1190px;
  margin: 40px auto 0 450px;
  padding-bottom: 5px;
  clear: both;
  font-size: 12px;
  color: #666;
  position: relative;
  z-index: 10;
}
.secitem {
  clear: both;
  line-height: 34px;
  overflow: hidden;
  padding-left: 19px;
  padding-right: 10px;
}
.secitem dt {
  float: left;
  white-space: nowrap;
  text-align: right;
  width: 75px;
  color: #999;
  padding-right: 10px;
}
.secitem dd {
  overflow: hidden;
  display: block;
  zoom: 1;
}
.secitem a {
  margin-right: 16px;
  color: #666;
  display: inline-block;
}
.secitem a,
.secitem span {
  display: inline-block;
  float: left;
  white-space: nowrap;
}
a:hover {
  color: #ff552e;
}
a,
a:hover,
a:link,
a:visited {
  text-decoration: none;
}
.containnerwrap {
  width: 1190px;
  margin: 25px auto 0 450px;
}
.clearfix {
  display: block;
}
#mainlist {
  width: 990px;
  float: left;
}
.pr {
  position: relative;
}
.tabsbar {
  width: 100%;
  height: 40px;
  line-height: 40px;
  font-size: 18px;
  border-bottom: 2px solid #ff552e;
  position: relative;
  z-index: 9;
}

.list-tabs {
  float: left;
}

.list-tabs,
.sel,
.list-tabs a:hover {
  display: inline-block;
  float: left;
  height: 40px;
  line-height: 40px;
  background: #ff552e;
  color: #fff;
  cursor: pointer;
  border-radius: 2px 2px 0 0;
  font-size: 18px;
  text-decoration-line: none;
}
.sel,
.sel2,
.sel3 {
  width: 100px;
  text-align: center;
}
.sel2,
.sel3 {
  height: 38px;
  float: left;
  background: #fff;
  color: #555;
}

.list-tabs a h1 {
  font-weight: 400;
  font-size: 18px;
}
#infolist.cleft {
  width: 990px;
  float: left;
}
table.small-tbimg {
  border-collapse: inherit;
}
.small-tbimg {
  width: 100%;
  font-size: 12px;
}
.small-tbimg td {
  border: none;
  line-height: 22px;
  vertical-align: middle;
  border-collapse: collapse;
}
.fengexian {
  display: inline-block;
  width: 990px;
  margin-top: 160px;
  margin-left: -920px;
  border-bottom: solid #eee 1px;
}
td.img {
  width: 162px;
  margin: 0 auto;
}
td.img div {
  width: 200px;
}
.ac_linkurl a {
  position: relative;
  left: 470px;
  margin: 0 auto;
  color: #333;
  font-size: 17px;
  font-weight: 600;
  align-items: center;
}
td .t {
  line-height: 22px;
  padding: 20px 6px 15px 10px;
  vertical-align: top;
}
.small-tbimg td.t {
  color: #666;
}
.tdiv {
  float: left;
  overflow: hidden;
  display: inline;
  margin-left: 550px;
}
.new-list .new-long-tit2 {
  max-width: 730px;
  overflow: hidden;
}
.new-list .t a {
  display: inline-block;
}
.new-list .new-long-tit {
  font-family: PingFangSC-Regular;
  font-size: 20px;
  color: #333;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
  margin-bottom: 50px;
  margin-right: 5px;
  max-width: 730px;
  overflow: hidden;
  text-overflow: ellipsis;
  float: left;
}
.new-list .new-long-tit:hover,
a:hover {
  color: #ff552e;
}
.new-list .item-desc {
  width: 600px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: -20px;
}
.item-desc {
  clear: both;
  margin-top: 5px;
  color: #999;
}
.seller {
  clear: both;
  margin-top: 5px;
  line-height: 20px;
  padding-bottom: 10px;
}
p {
  margin: 0;
}
a.c6 {
  color: #666 !important;
}
.es {
  display: block;
  width: 300px;
  color: #fc4e28;
  text-align: center;
  position: relative;
  top: 80px;
  left: -200px;
}
.es .my {
  font-size: 16px;
  color: #fc4e28;
  line-height: 2;
  font-weight: 600;
}
.logo {
  display: flex;
  font-size: 20px;
  font-weight: 800;
  margin-top: -45px;
  margin-left: 220px;
  color: rgba(177, 171, 171, 0.966);
}
.bottom {
  overflow: hidden;
  width: 100%;
  height: 150px;
  /* background-color: red; */
  /* border: 1px solid black; */
  margin: 0 auto;
  line-height: 16px;
  text-align: center;
  color: #999;
}
.bottom div {
  height: 50px;
  line-height: 50px;
  width: 100%;
  margin-top: 90px;
  border-top: 1px solid #999;
}
.bottom div span {
  margin: 0 10px;
  color: #eee;
}

.bottom div a {
  color: #999;
  font-size: 13px;
  /* margin-left: 20px; */
}

.page-bar {
  /* position: absolute;
  bottom: -450px;
  left: 900px; */
  margin-top: 1700px;
  margin-left: 550px;
}
ul,
li {
  margin: 0px;
  padding: 0px;
}
li {
  list-style: none;
  float: left;
}
.page-bar li:first-child > a {
  margin-left: 0px;
}
.page-bar a {
  border: 1px solid #ddd;
  text-decoration: none;
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: -1px;
  line-height: 1.42857143;
  color: #5d6062;
  cursor: pointer;
  margin-right: 20px;
}
.page-bar a:hover {
  background-color: #eee;
}
.page-bar a.banclick {
  cursor: not-allowed;
}
.page-bar .active a {
  color: #fff;
  cursor: default;
  background-color: #e96463;
  border-color: #e96463;
}
.page-bar i {
  font-style: normal;
  color: #d44950;
  margin: 0px 4px;
  font-size: 12px;
}
</style>
